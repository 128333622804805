<template>
    <div class="busca-final">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Lenguaje"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1619824887/juegos-cramer/tutoriales/nivel1/percepcion/final_de_la_palabra_mixmkc.mp4"
        text="Busque el final correcto para cada palabra"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning" >
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="bf__game-box-container">
                <div class="bf__game-box">
                    <div class="bf__main-word-container">
                        <div class="bf__word">
                            {{word_selected}}__
                        </div>
                    </div>
                    <div class="bf__alternatives">
                        <div 
                        class="bf__alternative-ta"
                        v-for="(item,index) in alternatives"
                        :key="item"
                        @click="getKeyAns(item,index)"
                        >
                            {{item}}
                            <div :class="['bf__check-answer',index_answer_selected==index?checkAnswer:'']"></div>
                        </div>
                    </div>
                    <div v-if="capaProtectora" class="bf__capa-protectora"></div>
                </div>
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="busca final de la palabra" nivel="1" inteligencia="lenguaje"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'

export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame,
    },
    data:()=>({
        puntos:0,
        serie:[],
        gameRuning:true,
        visibleAnswer:false,
        checkAnswer:'',
        index_answer_selected:0,
        capaProtectora:false,
        alternatives:['TA','NO','DO'],
        word_selected:'',
        key_letters:{},
        random_prev:null
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
         ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
       getKeyAns(letter,index){
           this.index_answer_selected = index
           if(this.key_letters[this.word_selected]==letter)
           {
               this.puntos+=40;
               let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
               a.play()
               this.checkAnswer = 'bf__correct-answer'
           }
           else{
               this.puntos-=20;
               let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
               a.play()
               this.checkAnswer = 'bf__wrong-answer'
           }
           this.capaProtectora = true;
           window.setTimeout(()=>{
               this.gameGenerator()
               this.capaProtectora = false
           },1000)
       },
       setGameRuning(){
                this.gameRuning = false
                if(this.puntos<0)
                {
                    this.changeScoreGame(0)
                    this.changeScoreDescription('insuficiente')
                }
                else{
                    this.changeScoreGame(this.puntos)
                    if(this.puntos<=200){
                    this.changeScoreDescription('insuficiente')
                    }
                    else if(this.puntos<=400){
                        this.changeScoreDescription('escasa')
                    }
                    else if(this.puntos<=600){
                        this.changeScoreDescription('suficiente')
                    }
                    else if(this.puntos<=800){
                        this.changeScoreDescription('buena')
                    }
                    else{
                        this.changeScoreDescription('optima')
                    }
                }
            },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
        getArrayRandom(min,max,cantidad){
            //let total = max-min;
            let res = [];
            while(!(res.length==cantidad))
            {
                let aux = this.getRandom(min,max);
                res.push(aux)
            }
            return res;
        },
        reordenarArray(arr){
                let a = arr.sort(function() { return Math.random() - 0.5 })
            // console.log(a)
                return a;
            },
        gameGenerator(){
            this.checkAnswer=''
            this.key_letters ={}
            let palabras = ["ATLE", "BLAN", "CASE", "PLAN", "ORON", "PALE", "CUES", "PELO", "PUER", "RAYA", "FLAU", "HUER", "LACA", "PUÑA", "SALA", "PELA", "SERE", "TIMI", "CARE", "ENEL", "PASA", "OCÉA", "MORE", "PATA", "CICU", "NAVA", "GABE", "COPE", "MULE", "ENFA", "FAJA", "DECA", "MECA", "DEGA", "BANA", "RABI", "NOVE"];
            let soluciones = [1, 3, 1, 1, 3, 1, 1, 1, 1, 3, 1, 1, 3, 3, 3, 3, 2, 3, 1, 3, 3, 2, 2, 1, 1, 1, 1, 1, 1, 3, 3, 2, 2, 2, 2, 2, 2, 2, 2];
            let index_random = this.getRandom(0,31)
            
            index_random = this.random_prev == index_random? index_random:this.getRandom(0,31)
            let key = palabras[index_random];
            let letter_key='';
            if(soluciones[index_random]==1)
            { 
                letter_key='TA'
            }
            else if(soluciones[index_random]==2)
            {
                letter_key='NO'
            }
            else{
                letter_key='DO'
            }

            this.key_letters[key] = letter_key
            this.word_selected = key
            this.random_prev = index_random
        }
    },
    mounted(){
        this.random_prev = this.getRandom(0,31)
        this.gameGenerator()
        this.changeMenuBarBottom(false)
        this.changeTutorialStatus(true)
    }
}
</script>
<style>
.busca-final{
    width: 100%;
    min-height: 100vh;
    background-color: #1F0275;
}
.bf__game-box-container{
    width: 100%;
    margin-top:2em;
    display: flex;
    justify-content: center;
}
.bf__game-box{
    width: 90%;
    height: 53vh;
    background-color: #EEEFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}
.bf__main-word-container{
    width: 90%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bf__word{
    font-size: 4em;
    background-color: rgb(185, 143, 45);
    width: 3em;
    text-align: center;
    border:solid 2px #343434;
    border-radius: 10px;
}
.bf__alternatives{
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.bf__alternative-ta{
    font-size: 2em;
    background-color: rgb(167, 84, 46);
    color: white;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}
.bf__check-answer{
    position: absolute;
    width: 0.9em;
    height: 0.9em;
    top: 0px;
    right: 0px;
}
.bf__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.bf__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.bf__capa-protectora{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
    opacity: 0;
}
@media (min-width: 900px) and (orientation: landscape) {
    .bf__game-box{
        width: 60%;
    }
}
</style>